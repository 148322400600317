<template>
    <div class="container">
        <base-dialog :show="!!error" title="An error occured!" @close="handleError"> 
            <p>{{ error }}</p>
        </base-dialog>
        <base-dialog :show="customPopupMessage != ''" :title="customPopupTitle" @close="closeMessagePopup"> 
            <p><span v-html="customPopupMessage"></span></p>
        </base-dialog>
        <section>
            <div class="info-container">
                <div class="title-container">
                    <h1 class="title">{{ $t("consultationpage.title") }}</h1>
                </div>
                <div class="row first-row">
                    <img src="../assets/images/working.png" alt="person coding">
                    <div class="description">
                        <h2 class="colored-text">{{ $t("consultationpage.sections.titles.1") }}</h2>
                        <p>{{ $t("consultationpage.sections.texts.1") }}</p>
                    </div>
                </div>
                <div class="row row-reverse second-row">
                    <img src="../assets/images/containers.png" alt="containers ship">
                    <div class="description description-reverse">
                        <h2 class="colored-text">{{ $t("consultationpage.sections.titles.2") }}</h2>
                        <p>{{ $t("consultationpage.sections.texts.2") }}</p>
                    </div>
                </div>
                <div class="row third-row">
                    <img src="../assets/images/social.png" alt="group of persons">
                    <div class="description">
                        <h2 class="colored-text">{{ $t("consultationpage.sections.titles.3") }}</h2>
                        <p>{{ $t("consultationpage.sections.texts.3") }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="contact-text-container">
                <p>{{ $t("consultationpage.sections.texts.4-1") }} <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#099;&#111;&#100;&#101;&#114;&#101;&#102;&#108;&#101;&#120;&#046;&#099;&#097;">&#105;&#110;&#102;&#111;&#064;&#099;&#111;&#100;&#101;&#114;&#101;&#102;&#108;&#101;&#120;&#046;&#099;&#097;</a> {{ $t("consultationpage.sections.texts.4-2") }}</p>
            </div>
            <div class="contact-container">
                <h1 class="space-left">{{ $t("contact.title") }}<i class="fas fa-envelope-open-text half-space-left"></i></h1>
                <form @submit.prevent="submitForm">
                    <div class="form-control">
                        <label for="name">{{ $t("contact.name") }}<i class="fas fa-user half-space-left"></i></label>
                        <input type="name" id="name" v-model.trim="name">
                    </div>
                    <div class="form-control">
                        <label for="email">{{ $t("contact.email") }}<i class="fas fa-envelope half-space-left"></i></label>
                        <input type="email" id="email" v-model.trim="email">
                    </div>
                    <div class="form-control">
                        <label for="description">{{ $t("contact.message") }}<i class="fas fa-comment-dots half-space-left"></i></label>
                        <textarea id="description" name="description" rows="4" cols="50" v-model.trim="description"></textarea>
                    </div>
                    <p v-if="!formIsValid" class="colored-text">{{ $t("contact.emptyFields") }}</p>
                    <button class="button" :class="fieldsEmpty ? 'order-button-disabled' : ''">{{ $t("contact.button") }}<i class="fas fa-paper-plane half-space-left"></i></button>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            error: null,
            formIsValid: true,
            email: '',
            name: '',
            description: '',
            customPopupTitle: '',
            customPopupMessage: ''
        };
    },
    methods: {
        async submitForm() {
            if(this.email === '' ||
                this.name === '' ||
                this.description === '' ||
                !this.email.includes('@')
            ) {
                this.formIsValid = false;
                return;
            }

            this.isLoading = true;

            const actionPayload = {
                email: this.email,
                name: this.name,
                description: this.description
            }

            try {
                await this.$store.dispatch('requests/sendContactMessage', actionPayload);
                window.scrollTo(0, 0);
                this.customPopupTitle = this.$t("dialog.emailSent.title");
                this.customPopupMessage = this.$t("dialog.emailSent.message");
                this.email = '';
                this.name = '';
                this.description = '';
            } catch (error) {
                this.error = error.message || "Une erreur c'est produite.";
            }

            this.isLoading = false;
        },
        async closeMessagePopup() {
            this.customPopupTitle = '';
            this.customPopupMessage = '';
        },
        handleError() {
            this.error = null;
        }
    },
    computed: {
        fieldsEmpty() {
            return this.email === '' ||
                this.name === '' ||
                this.description === '' ||
                !this.email.includes('@')
        }
    },
    mounted() {
        this.$store.dispatch('requests/getLocale');
        const locale = this.$store.getters['requests/locale'];

        if(locale) {
            this.$i18n.locale = locale;
        } else {
            let language = window.navigator.userLanguage || window.navigator.language;
            language.toLowerCase();
            if(language.includes('fr')) {
                this.$i18n.locale = 'fr';
            }
        }
    }
}
</script>

<style scoped>

.container {
    top: 5rem;
}

.contact-container {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #201C1A;
}

.contact-text-container {
    margin-top: 17rem;
    padding: 1rem;
    background-color: #201C1A;
}


.info-container {
    margin-top: 7rem;
}

form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

.row {
    position: relative;
    margin-top: 14rem;
}

.first-row {
    margin-top: -2.5rem;
}
.second-row {
    margin-top: 13rem;
}
.third-row {
    margin-top: 12rem;
}
.fourth-row {
    margin-top: 15rem;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  color: #FFF5EC;
}

h1, h2, h3 {
    color: #FFF5EC;
}

p {
    color: #FFF5EC;
}

a {
    text-decoration: none;
    color: #F85625;
}

.colored-text {
    color: #F85625;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin: 0 1rem;
    text-align: center;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #A63B1B;
  background-color: #37312F;
  padding: 0.15rem;
  color: #FFF5EC;
}

input:focus,
textarea:focus {
  border-color: #F85625;
  outline: none;
}

img {
    margin-top: 5rem;
    margin-bottom: 5rem;
    display:block;
    margin-left:auto;
    margin-right:auto;
    max-width: 100%;
    height: auto;
}

.button {
    margin-top: 1rem;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font: inherit;
    background-color: #F85625;
    border: 1px solid #F85625;
    color: #FFF5EC;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 0.5rem;
    display: inline-block;
}

.button:hover,
.button:active {
    background-color: #F5815E;
    border-color: #F5815E;
}

.space-left {
    margin-left: 1rem;
}

.half-space-left {
    margin-left: 0.5rem;
}

.description {
    padding: 1rem;
    background-color: #201C1A;
    margin: 1rem;
    border-radius: 15px;
    top: 15rem;
    position: absolute;
}

.description-reverse-small {
    top: 20rem;
}

.order-button-disabled {
    background-color: #cecece !important;
    color: #8a8a8a !important;
    cursor: default;
    border-color: #cecece !important;
}

@media (min-width: 40rem){
    .contact-container {
        max-width: 30rem;
        border-radius: 15px;
        margin: auto;
        margin-top: 2rem;
    }

    .contact-text-container {
        max-width: 30rem;
        border-radius: 15px;
        margin: auto;
        margin-top: 8rem;
    }
    
    .info-container {
        padding: 1rem;
    }

    img {
        border-radius: 15px;
        max-height: 70vh;
        margin: 0;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 7rem;
    }

    .first-row {
        margin-top: 3.5rem;
    }

    .row-reverse {
        justify-content: flex-end;
    }

    .description {
        top: initial;
        left: 24vw;
        bottom: -2rem;
        background-color: #201C1A;
        margin-right: 2rem;
    }

    .description-reverse {
        left: 1rem;
        margin-right: 25vw;
        text-align: right;
    }

    .description-reverse-small {
        min-width: 20rem;
    }
}

@media (max-width: 24rem){
    .row {
        margin-top: 25rem;
    }

    .first-row {
        margin-top: -2.5rem;
    }

    .contact-text-container {
        margin-top: 26rem;
    }
}

</style>