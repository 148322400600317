export default {
    async sendContactMessage(context, payload) {
        let url = `${process.env.VUE_APP_API_BASE_URL}/api/email/contact`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: payload.email,
                name: payload.name,
                description: payload.description
            })
        });

        const responseData = await response.json();

        if(!response.ok) {
            const error = new Error(responseData.message || "Une erreur c'est produite");
            throw error;
        }
    },
    async setLocale(content, payload) {
        localStorage.setItem('locale', payload.locale);
    },
    async getLocale(context) {
        const locale = localStorage.getItem('locale');

        if(locale) {
            context.commit('setLocale', {
                locale: locale
            });
        }
    },
};