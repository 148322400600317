<template>
    <div class="card" :class="mode">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            required: false,
            default: 'regular'
        }
    }
}
</script>

<style scoped>
.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem 2rem; 
  margin: 2rem auto;
  background-color: #201C1A;
  color: #FFF5EC;
}
.regular {
  max-width: 40rem;
}
.large {
  max-width: 80%;
}
</style>