import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import LocaleDropdown from './components/ui/LocaleDropdown';
import frTranslation from './translations/fr.json';
import enTranslation from './translations/en.json';

const messages = {
    fr: frTranslation,
    en: enTranslation
  }

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages
})

const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('locale-dropdown', LocaleDropdown);

app.mount('#app');