import { createRouter, createWebHistory } from "vue-router";

import NotFound from './views/NotFound.vue';
import HomePage from './views/HomePage.vue';
import ConsultationPage from './views/ConsultationPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home'  },
        { path: '/home', component: HomePage },
        { path: '/consultation', component: ConsultationPage },
        { path: '/:notFound(.*)', component: NotFound },
    ],
});

export default router;