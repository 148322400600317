<template>
    <the-header></the-header>
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
    <the-footer></the-footer>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue';

export default {
    components: {
        TheHeader,
        TheFooter
    }
}
</script>

<style>
@font-face {
  font-family: "MainFont";
  src: url(./fonts/Ginขra-Sans-Regular.otf);
}

@font-face {
  font-family: "HeaderFont";
  src: url(./fonts/MusticaPro-SemiBold.otf);
}

* {
  box-sizing: border-box;
}

html {
  font-family: "MainFont", sans-serif;
}

h1, h2, h3 {
  font-family: "HeaderFont", sans-serif;
}

body {
  margin: 0;
  background: linear-gradient(135deg, #2b1e1b 0%, #49332e 25%, #a84c37 50%, #504744 75%, #37312f 100%);
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-to
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>