<template>
    <button v-if="!link" :class="mode">
        <slot></slot>
        <slot name="icon"></slot>
    </button>
    <router-link v-else :to="to" :class="mode">
        <slot></slot>
        <slot name="icon"></slot>
    </router-link>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            required: false,
            default: null
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: '/'
        }
    }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  background-color: #F85625;
  border: 1px solid #F85625;
  color: #FFF5EC;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 0.5rem;
  display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: #FFF5EC;
  border-color: #FFF5EC;
  color: #F85625;
}

.flat {
  background-color: transparent;
  color: #FFF5EC;
  border: none;
}

.outline {
  background-color: transparent;
  border-color: #FFF5EC;
  color: #FFF5EC;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  color: #F85625;
}
</style>