<template>
    <div class="container">
        <section>
            <base-card>
                <h2>{{ $t("notFound.title") }}</h2>
                <p>{{ $t("notFound.text") }} <router-link to="/home">{{ $t("notFound.link") }}</router-link>.</p>
            </base-card>
        </section> 
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    mounted() {
        this.$store.dispatch('requests/getLocale');
        const locale = this.$store.getters['requests/locale'];

        if(locale) {
            this.$i18n.locale = locale;
        } else {
            let language = window.navigator.userLanguage || window.navigator.language;
            language.toLowerCase();
            if(language.includes('fr')) {
                this.$i18n.locale = 'fr';
            }
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #F85625;
}

.container {
    height: 80vh;
    margin-top: 30vh;
}
</style>