<template>
    <div class="locale-changer">
        <select @change="saveLocale" ref="localeDropdown" v-model="$i18n.locale">
            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
                {{ locale.toUpperCase() }}
            </option>
        </select>
        <span @click="openDropdown">
            <i class="fas fa-globe quarter-space-left"></i>
        </span>
    </div>
</template>

<script>
export default {
    methods: {
        openDropdown() {
            let event = new MouseEvent('mousedown');
            this.$refs.localeDropdown.dispatchEvent(event);
        },
        saveLocale() {
            const actionPayload = {
                locale: this.$i18n.locale
            }

            this.$store.dispatch('requests/setLocale', actionPayload);
        }
    }
}
</script>

<style scoped>
.locale-changer select {
    appearance: none;
    background: none;
    color: white;
    border: none;
    font-size: 16px;
    outline: none;
}

.quarter-space-left {
  margin-left: 0.25rem;
}
</style>