<template>
    <header>
        <nav>
            <router-link class="logoLink" to="/">
              <img class="navLogo" srcset="../../assets/svg/codereflex-logo-transparent.svg" alt="code reflex logo">
              <h1 class="space-left">{{ $t("header.title") }}</h1>
            </router-link>
            <ul class="navList">
                <li><locale-dropdown></locale-dropdown></li>
            </ul>
            <div class="hamburger-button-container" @click="isDropdownVisible = !isDropdownVisible" :class="isDropdownVisible ? 'rotate-icon' : 'rotate-icon-reverse'">
              <i class="fas fa-bars hamburger-button"></i>
            </div>
        </nav>
    </header>
    <ul v-if="isDropdownVisible" class="mobile-navList" @click="isDropdownVisible = false">
        <li class="mobile-navList__item" @click.stop=""><locale-dropdown></locale-dropdown></li>
    </ul>
    <div v-if="isDropdownVisible" class="backdrop" @click="isDropdownVisible = false"></div>
</template>

<script>
import LocaleDropdown from '../ui/LocaleDropdown.vue';
export default {
  components: { LocaleDropdown },
  data() {
    return {
      isDropdownVisible: false
    }
  }
}
</script>

<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #201C1A;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: 0;
}

a {
  text-decoration: none;
  color: #FFF5EC;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a.navButton {
  text-decoration: none;
  color: #FFF5EC;
  background-color: #F85625;
  border-radius: 30px;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

.navList {
    display: none;
}

.mobile-navList__item {
  margin: 1rem 0;
}

a:active.navButton,
a:hover.navButton,
a.router-link-active.navButton{
  background-color: #FFF5EC;
  color: #F85625;
}

h1 {
  margin: 0;
}

h1 a {
  color: #FFF5EC;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

.space-left {
  margin-left: 1rem;
}

.half-space-left {
  margin-left: 0.5rem;
}

nav {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.mobile-navList {
  border-top: 1px solid #37312F;
  border-bottom: 1px solid #37312F;
  margin: 0;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #201C1A;
  animation: show-dropdown 400ms forwards;
  z-index: 6;
}

.navLogo {
  height: 3rem;
}

.logoLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
  color: #FFF5EC;
}

.hamburger-button-container {
  padding: 0.75rem 1.5rem;
}

.hamburger-button {
  color: #FFF5EC;
  font-size: 1.5rem;
}

.rotate-icon {
  animation: rotate-icon 400ms forwards;
}

.rotate-icon-reverse {
  animation: rotate-icon-reverse 400ms forwards;
}

.backdrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 5;
  opacity: 0.7;
  animation: show-backdrop 400ms forwards;
}

@media (min-width: 40rem){
  .navList {
    display: flex;
    margin-right: 1rem;
  }
  .mobile-navList {
    display: none;
  }
  .hamburger-button-container {
    display: none;
  }

  .backdrop {
    display: none;
  }
}

@keyframes show-dropdown {
  0% {
    top: -5rem;
  }
  100% {
    top: 5rem;
  }
}

@keyframes rotate-icon {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate-icon-reverse {
  0%{
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes show-backdrop {
  100% {
    background-color: #201C1A;
  }
}
</style>