<template>
    <div class="container">
        <h1 class="title gradient-title">{{ $t("homepage.title") }}</h1>
        <h3 class="title gradient-pale-text ">{{ $t("homepage.texts.1") }}</h3>
        <h2 class="title gradient-text">{{ $t("homepage.texts.2") }}</h2>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.container {
    padding: 7rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 250vh;
}

h1, h2, h3 {
    color: #FFF5EC;
}

.gradient-title {
  background: -webkit-linear-gradient(#F85625, #e82525);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-pale-text {
  background: -webkit-linear-gradient(#FFF5EC, #ddc9c9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text {
  background: -webkit-linear-gradient(#F85625, #f07474);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
    margin: 1rem;
    text-align: center;
}

.space-left {
    margin-left: 1rem;
}

.half-space-left {
    margin-left: 0.5rem;
}

@media (min-width: 40rem){
    
    .info-container {
        padding: 1rem;
    }

    .container {
        padding: 10rem 5rem;
    }

}

</style>