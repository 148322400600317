<template>
    <footer class="main-footer">
        <hr class="fancy-hr">
        <div class="footer-text-container">
            <p class="footer-text">{{ $t("footer.copyright") }}</p>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.main-footer {
    padding: 2rem;
    margin-top: 3rem;
}

.footer-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text {
    font-family: sans-serif;
    color: #A63B1B;
}

.fancy-hr {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, transparent, #A63B1B, transparent);  
}

@media (min-width: 40rem){
    .fancy-hr {
        margin-bottom: 2rem;
    }
}
</style>